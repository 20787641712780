<template class="text_google">
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>

        <v-btn
          @click.stop="
            Export2Doc('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1"
          color="blue"
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
        <v-btn
          @click.stop="
            exportToExcel('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1"
          color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <div v-for="(item,index) in transference_personnels" :key="item.id">
          <br>
          <div align="center">
            <div class="head">
              แบบบันทึกผลคะแนน ตัวชี้วัดและคะแนนในการประเมินตามองค์ประกอบที่ 7 การประเมินศักยภาพของผู้ประสงค์ขอย้าย
            </div>
            <div class="head">
              ตำแหน่งครู (กรณีปกติ) ประจำปี พ.ศ. {{ period_year }} ครั้งที่
              {{ period_times }} สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
            </div>
          </div>

          <table width="100%" class="text_j">
            <tr>
              <td width="15%">ชื่อผู้ขอย้าย</td>
              <td width="40%" class="text-center td_line">{{ item.personnel_name }}</td>
              <td width="15%" class="text-center">ตำแหน่ง</td>
              <td width="30%" class="text-center td_line">{{ item.position_name }}</td>
            </tr>

            <tr>
              <td width="15%">วิทยาลัย</td>
              <td width="40%" class="text-center td_line">{{ item.college_name }}</td>
              <td width="15%" class="text-center">ตำแหน่งเลขที่</td>
              <td width="30%" class="text-center td_line">{{ item.id_position }}</td>
            </tr>
          </table>

          <br />
          <table width="100%" class="table text_j">
            <tr>
              <th width="10%" class="text-center td">ตัวชี้วัดที่</th>
              <th width="25%" class="text-center td">องค์ประกอบ/ตัวชี้วัด</th>
              <th
                width="10%"
                height="150"
                class="text-center td"
                valign="bottom"
              >
                <span class="textAlignVer pt-12">เกณฑ์การให้คะแนน</span>
              </th>
              <th width="15%" class="text-center td">คะแนนที่ได้รับ</th>
              <th width="40" class="text-center td">กรอบการพิจารณา</th>
            </tr>
            <tr>
              <td class="text-center td">7.1</td>
              <td colspan="4" class="td pa-1">
                การแสดงภาระงานในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/หรือตรงกับความต้องการของสถานศึกษาที่รับย้าย
                (คะแนน 20 คะแนน)
              </td>
            </tr>

            <tr>
              <td class="text-center td">7.1.1</td>
              <td colspan="2" class="td pa-1">
                ความรู้ในสาขาวิชาที่ย้ายไปดำรงตำแหน่ง
              </td>
              <td rowspan="5" class="td"></td>
              <td class="td pa-1">
                <div class="text_pal">
                  พิจารณาจากสำเนา ก.พ. 7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน
                  โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้อง
                  มีวุฒิ (ปริญญาตรี , ปริญญาโท , ปริญญาเอก)
                  ที่ตรงกับวิชาเอกหรือที่เกี่ยวข้องกับการจัดการเรียนการสอน
                </div>
                <div class="text_pal">
                  ทั้งนี้
                  ผู้ประสงค์ขอย้ายต้องลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารประกอบการพิจารณาทุกฉบับ
                </div>
              </td>
            </tr>

            <tr>
              <td class="text-center td">7.1.2</td>
              <td colspan="2" class="td pa-1">
                ความสามารถในการปฏิบัติหน้าที่ในวิชาเอกหรือที่เกี่ยวข้องกับการจัดการเรียนการสอนที่รับย้ายหรือที่ผู้บังคับบัญชามอบหมาย
              </td>
              <td class="td pa-1">
                <div class="text_pal">
                  พิจารณาจากเป็นผู้รับผิดชอบที่ทำหน้าที่หัวหน้างาน/หัวหน้าแผนกวิชา/งานที่สถานศึกษามอบหมาย/การได้รับมอบหมายงาน/โครงการ/กิจกรรมในสถานศึกษาปัจจุบัน
                  นับถึงวันสุดท้ายที่กำหนดให้ยื่นคำร้อง ขอย้ายประจำปี
                  โดยแนบคำสั่งของสถานศึกษาปัจจุบันที่มอบหมายให้ปฏิบัติงานและไม่ต้องแนบรูปภาพประกอบ
                </div>
                <div class="text_pal">
                  ทั้งนี้
                  ให้ผู้ประสงค์ขอย้ายและผู้บังคับบัญชาหรือผู้รักษาการในตำแหน่งที่สำนักงานคณะกรรมการการอาชีวศึกษาแต่งตั้งตามลำดับ
                  ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
                </div>
              </td>
            </tr>

            <tr>
              <td class="text-center td">7.1.3</td>
              <td colspan="2" class="td pa-1">
                การพัฒนาตนเอง
                โดยได้รับการอบรมสัมมนารวมทั้งศึกษาดูงานเพิ่มพูนความรู้ตนเอง
              </td>
              <td class="td pa-1">
                <div class="text_pal">
                  พิจารณาจากสำเนาเกียรติบัตร วุฒิบัตร
                  หรือสำเนาเอกสารหลักฐานที่แสดงให้เห็นถึงการอบรมสัมมนารวมทั้งศึกษาดูงานเพิ่มพูนความรู้ตนเอง
                  และศึกษาต่อย้อนหลังไม่เกิน 1 ปี
                  นับถึงวันสุดท้ายของกำหนดการยื่นคำร้องขอย้ายประจำปี
                </div>
                <div class="text_pal">
                  ทั้งนี้
                  ผู้ประสงค์ขอย้ายต้องลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารประกอบการพิจารณาทุกฉบับ
                </div>
              </td>
            </tr>
          </table>

          <div class="text-right">
          {{ index+1 }}  หน้า <v-chip color="grey" dark> <h2>1</h2> </v-chip>
          </div>

          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 2 -->

          <br />
          <br />

          <table width="100%" class="text_j">
            <tr>
              <td width="15%">ชื่อผู้ขอย้าย</td>
              <td width="40%" class="text-center td_line">{{ item.personnel_name }}</td>
              <td width="15%" class="text-center">ตำแหน่ง</td>
              <td width="30%" class="text-center td_line">{{ item.position_name }}</td>
            </tr>

            <tr>
              <td width="15%">วิทยาลัย</td>
              <td width="40%" class="text-center td_line">{{ item.college_name }}</td>
              <td width="15%" class="text-center">ตำแหน่งเลขที่</td>
              <td width="30%" class="text-center td_line">{{ item.id_position }}</td>
            </tr>
          </table>

          <br />

          <table width="100%" class="table text_j">
            <tr>
              <th width="10%" class="text-center td">ตัวชี้วัดที่</th>
              <th width="25%" class="text-center td">องค์ประกอบ/ตัวชี้วัด</th>
              <th
                width="10%"
                height="150"
                class="text-center td"
                valign="bottom"
              >
                <span class="textAlignVer pt-12">เกณฑ์การให้คะแนน</span>
              </th>
              <th width="15%" class="text-center td">คะแนนที่ได้รับ</th>
              <th width="40" class="text-center td">กรอบการพิจารณา</th>
            </tr>

            <tr>
              <td class="text-center td">7.1.4</td>
              <td colspan="2" class="td pa-1">
                การพัฒนาวิชาชีพ โดยได้รับแต่งตั้งเป็นคณะกรรมการ อนุกรรมการ
                คณะทำงาน หรือวิทยากร นวัตกรรมสิ่งประดิษฐ์
                การพัฒนาสื่อการเรียนการสอน งานวิจัย
              </td>
              <td class="td"></td>
              <td class="td pa-1" rowspan="2">
                <div class="text_pal">
                  พิจารณาจากสำเนาเกียรติบัตร วุฒิบัตร
                  หรือสำเนาเอกสารหลักฐานที่แสดงให้เห็นถึงการได้รับแต่งตั้งเป็นคณะกรรมการ
                  อนุกรรมการ คณะทำงาน หรือวิทยากร นวัตกรรมสิ่งประดิษฐ์
                  การพัฒนาสื่อ การเรียนการสอน งานวิจัย ย้อนหลังไม่เกิน 1 ปี
                  นับถึงวันสุดท้ายของกำหนดการยื่นคำร้องขอย้ายประจำปี
                </div>
                <div class="text_pal">
                  ทั้งนี้
                  ให้ผู้ประสงค์ขอย้ายและผู้บังคับบัญชาหรือผู้รักษาการในตำแหน่งที่สำนักงานคณะกรรมการการอาชีวศึกษาแต่งตั้งตามลำดับ
                  ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
                </div>
              </td>
            </tr>

            <tr>
              <td class="text-center"></td>
              <td colspan="2" class="pa-1">
                เกณฑ์การพิจารณาการให้คะแนน <br />
                ระดับดีมาก..........18 – 20 คะแนน<br />
                ระดับดี.................15 – 17 คะแนน<br />
                ระดับปานกลาง....12 - 14 คะแนน<br />
                ระดับน้อย.............9 – 11 คะแนน <br />
                ระดับน้อยที่สุด......0 – 8 คะแนน
              </td>
              <td class="pa-1"></td>
           
            </tr>

            <tr>
              <td
                height="50"
                colspan="2"
                class="text-center td font-weight-bold"
                style="background-color: #efcbba;"
              >
                รวม
              </td>
              <td class="pa-1 td text-center font-weight-bold">20</td>
              <td class="pa-1 td"></td>
              <td class="pa-1 td" style="background-color: #efcbba;"></td>
            </tr>
          </table>

          <v-spacer></v-spacer>
          <div class="text-right">
           {{ index+1 }}  หน้า <v-chip color="grey" dark> <h2>2</h2> </v-chip>
          </div>
          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 3 -->

          <br />
          <table width="100%" class="text_j">
            <tr>
              <td width="15%">ชื่อผู้ขอย้าย</td>
              <td width="40%" class="text-center td_line">{{ item.personnel_name }}</td>
              <td width="15%" class="text-center">ตำแหน่ง</td>
              <td width="30%" class="text-center td_line">{{ item.position_name }}</td>
            </tr>

            <tr>
              <td width="15%">วิทยาลัย</td>
              <td width="40%" class="text-center td_line">{{ item.college_name }}</td>
              <td width="15%" class="text-center">ตำแหน่งเลขที่</td>
              <td width="30%" class="text-center td_line">{{ item.id_position }}</td>
            </tr>
          </table>
         <br>

          <table width="100%" class="table text_j">
            <tr>
              <th width="10%" class="text-center td">ตัวชี้วัดที่</th>
              <th width="25%" class="text-center td">องค์ประกอบ/ตัวชี้วัด</th>
              <th
                width="10%"
                height="150"
                class="text-center td"
                valign="bottom"
              >
                <span class="textAlignVer pt-12">เกณฑ์การให้คะแนน</span>
              </th>
              <th width="15%" class="text-center td">คะแนนที่ได้รับ</th>
              <th width="40" class="text-center td">กรอบการพิจารณา</th>
            </tr>

            <tr>
              <td class="text-center td">7.2</td>
              <td colspan="4" class="td pa-1">
                การแสดงความรู้ความสามารถในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/หรือตรงกับความต้องการของสถานศึกษาที่รับย้าย
                (คะแนน 20 คะแนน)
              </td>
            </tr>

            <tr>
              <td class="text-center td"></td>
              <td colspan="2" class="td pa-1">
                การเขียนประเด็นท้าทายในการพัฒนางานตามมาตรฐานวิทยฐานะที่ครองอยู่
                ที่ตรงตามหลักสูตรการจัดการเรียนการสอนในรายวิชาใดวิชาหนึ่งเพียงวิชาเดียว
                ที่เคยทำการสอนในสถานศึกษาเดิม
                และสามารถนำไปสู่การปฏิบัติจริงในสถานศึกษาที่ขอย้าย
                ให้เกิดผลเป็นรูปธรรมได้
              </td>
              <td class="td pa-1" rowspan="2"></td>
              <td class="td pa-1" rowspan="2">
                <div class="text_pal">
                  รูปแบบเป็นไปตามที่ ก.ค.ศ. กำหนด ในการเขียนข้อตกลงโดยพิจารณาจาก
                </div>
                <div class="text_pal">
                  1. ประเด็นท้าทาย เป็นไปตามมาตรฐานวิทยฐานะของผู้ขอย้าย
                  และมุ่งพัฒนาผู้เรียนเป็นหลัก
                </div>
                <div class="text_pal">
                  2. วิธีดำเนินการ มีความเป็นไปได้
                  เป็นรูปธรรมที่สามารถทำให้ประเด็น ท้าทายประสบความสำเร็จได้
                </div>
                <div class="text_pal">
                  3. ผลที่คาดหวัง เป็นผลที่เกิดขึ้นกับผู้เรียน /สถานศึกษา /ชุมชน
                  / สถานประกอบการ (ตามหลักเกณฑ์และวิธีการประเมินตำแหน่งและ
                  วิทยฐานะข้าราชการครูและบุคลากรทางการศึกษา ตำแหน่งครู
                  ตามหนังสือสำนักงาน ก.ค.ศ ที่ ศธ 0206.3/ว9 ลงวันที่ 24 พฤษภาคม
                  2564)
                </div>
                <div class="text_pal">
                  ทั้งนี้ ให้เขียนบรรยายแสดงความรู้ความสามารถ
                  ในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/หรือตรงกับความต้องการของสถานศึกษา
                  ที่รับย้าย เนื้อหาความยาวไม่เกิน 5 หน้า กระดาษ A4 แบบอักษร TH
                  SarabunIT๙ หรือ TH SarabunPSK ขนาดตัวอักษร 16 พอยท์
                  (กรรมการจะพิจารณาเอกสารเฉพาะหน้าที่ 1 – 5 เท่านั้น)
                </div>
              </td>
            </tr>

            <tr>
              <td class="text-center"></td>
              <td colspan="2" class="pa-1">
                เกณฑ์การพิจารณาการให้คะแนน <br />
                ระดับดีมาก..........18 – 20 คะแนน<br />
                ระดับดี.................15 – 17 คะแนน<br />
                ระดับปานกลาง....12 - 14 คะแนน<br />
                ระดับน้อย.............9 – 11 คะแนน <br />
                ระดับน้อยที่สุด......0 – 8 คะแนน
              </td>
            
            </tr>

            <tr>
              <td
                height="50"
                colspan="2"
                class="text-center td font-weight-bold"
                style="background-color: #efcbba;"
              >
                รวม
              </td>
              <td class="pa-1 td text-center font-weight-bold">20</td>
              <td></td>
              <td class="pa-1 td" style="background-color: #efcbba;"></td>
            </tr>

            <tr>
              <td
                height="50"
                colspan="2"
                class="text-center td font-weight-bold"
                style="background-color: #cfefba;"
              >
                รวมทั้งหมด
              </td>
              <td class="pa-1 td text-center font-weight-bold">40</td>
              <td class="td td_line"></td>

              <td class="pa-1 td" style="background-color: #cfefba;"></td>
            </tr>
          </table>
<br>
          <table width="100%" class="text_j">
            <tr>
              <td width="60%"></td>
              <td width="40%" class="text-center font-weight-bold">
                ลายมือชื่อ กรรมการประเมิน
              </td>
            </tr>


            <tr>
              <td></td>
              <td height="30px" class="text-center font-weight-bold">
               
              </td>
            </tr>

            <tr>
              <td></td>
              <td class="text-center">
                ลงชื่อ ............................................................... 
              </td>
            </tr>


            <tr>
              <td></td>
              <td class="text-center">
                (.....................................................................)   
              </td>
            </tr>


            <tr>
              <td></td>
              <td class="text-center">
                ตำแหน่ง ......................................................... 
              </td>
            </tr>

            <tr>
              <td></td>
              <td class="text-center">
                วันที่............./........................./.................
              </td>
            </tr>

          </table>

          <div class="text-right">
           {{ index+1 }}  หน้า <v-chip color="grey" dark> <h2>3</h2> </v-chip>
          </div>
               <!-- New Page -->
               <p style="page-break-before: always;">&nbsp;</p>
          <!-- 3 -->
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    transference_personnels: [],
    valid: true
  }),

  async mounted() {
      await this.sweetAlertLoading()
      await this.searchTimeYear();
      Swal.close();
  },

  methods: {
    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.period_times,
          year_s: this.period_year,
          type_move: "nm"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    },

    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  },

  computed: {
    period_times() {
      return this.$route.query.period_times;
    },
    period_year() {
      return this.$route.query.period_year;
    }
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
  overflow: auto;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.text_j_1 {
  border-collapse: collapse;
  text-justify: inter-word;
  line-height: 1;
}
.text_pal {
  text-indent: 1.5em;
  border-collapse: collapse;
  text-justify: inter-word;
  line-height: 1;
}

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }
}
</style>
